import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { Link } from "gatsby";

import "./style.scss";
// import TimeRangePicker from "./TimeRangePicker";
import TimeRangePicker2 from "./TimeRangePicker2";

function scrolltoele(ele) {
  const element = document.querySelector(ele);

  var headerOffset = 45;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition - headerOffset;
  const leftpanel = document.querySelector(".studiobooking__grid__left");
  leftpanel.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

function scrollToTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
const BASE_AMOUNT = 1500;
const TAX = 18 / 100;

export default function StudioBooking() {
  const [bookingDate, setBookingDate] = useState(getTodayDate(new Date()));
  const [todayDate, setTodayDate] = useState(getTodayDate(new Date()));
  const [bookingDate2, setBookingDate2] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponCodeValid, setIsCouponCodeValid] = useState(false);
  const [isCouponCodeInvalid, setIsCouponCodeInvalid] = useState(false);

  const [fetchingBookedSlots, setFetchingBookedSlots] = useState(false);

  // const [amountPayable, setAmountPayble] = useState(1500);
  const [discountAmount, setDiscountAmount] = useState(0);

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [website, setwebsite] = useState("");

  const [address, setaddress] = useState("");
  const [isTQAccepted, setisTQAccepted] = useState(false);
  const [gst, setgst] = useState("");

  const [errors, setErrors] = useState({});

  const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState(false);

  const [paymentId, setPaymentId] = useState("");
  const [orderId, setOrderId] = useState("");

  const [showBillingDetails, setShowBillingDetails] = useState(false);

  const [startTime, setStartTime] = useState({});
  const [endTime, setEndTime] = useState({});
  const [numberOfHours, setNumberOfHours] = useState(0);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [purpose, setPurpose] = useState("");

  const onChangeTimePicker = (startTime, endTime) => {
    const selectedSlot = {
      startTime: startTime,
      endTime: endTime,
    };
    setSelectedSlot(selectedSlot);
    setErrors({});
  };

  function handleCouponCodeChange(e) {
    setCouponCode(e.target.value);
  }
  function handleFirstNameChange(e) {
    setfirstname(e.target.value);
  }
  function handleLastNameChange(e) {
    setlastname(e.target.value);
  }
  function handleCompanyNameChange(e) {
    setcompanyname(e.target.value);
  }
  function handleEmailchange(e) {
    setemail(e.target.value);
    setErrors({ ...errors, email: undefined });
  }
  function handlePhoneChange(e) {
    setphone(e.target.value);
  }
  function handlePurposeChange(e) {
    setPurpose(e.target.value);
  }

  function handleWebsiteChange(e) {
    setwebsite(e.target.value);
  }
  function handleAddressChange(e) {
    setaddress(e.target.value);
  }
  function handleAddressChange(e) {
    setaddress(e.target.value);
  }
  function handleGstChange(e) {
    setgst(e.target.value);
  }

  useEffect(() => {
    setBookingDate(getTodayDate(new Date()));
    setTodayDate(getTodayDate(new Date()));
    document
      .querySelector(".layout__cursor")
      .classList.add("layout__cursor--hide");

    return () => {
      document
        .querySelector(".layout__cursor")
        .classList.remove("layout__cursor--hide");
    };
  }, []);

  useEffect(() => {
    fetchDateBookedSlots();
  }, [bookingDate]);

  useEffect(() => {
    if (!_.isEmpty(endTime)) {
      setNumberOfHours(endTime.value - startTime.value);
    } else {
      setNumberOfHours(0);
    }
  }, [JSON.stringify(endTime)]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorPay(
    orderid,
    amount,
    currency,
    name,
    email,
    phone,
    address
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    var options = {
      key: "rzp_live_qD3jxalGoIj9qD",
      amount: amount,
      currency: currency,
      name: name,
      description: "Studio Booking",
      image:
        "https://blackelephantdigital.com/assets/img/BE(LOGO)footer200x200.png",
      order_id: orderid,
      handler: function (response) {
        console.log("payment success");
        setPaymentId(response.razorpay_payment_id);
        setOrderId(response.razorpay_order_id);
        scrollToTop();
        setIsPaymentSuccessfull(true);

        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_order_id);
        console.log(response.razorpay_signature);
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: address,
      },
      theme: {
        color: "#000",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      console.log("payment failure");
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    });

    rzp1.open();
  }

  async function onFormSubmit(e) {
    e.preventDefault();

    if (_.isEmpty(endTime) || _.isEmpty(startTime)) {
      setErrors({ selectedSlot: "Please select a time slot" });
      scrolltoele("#time-slot-picker");
      return;
    }
    let data = {
      name: firstname + " " + lastname,
      firstname,
      lastname,
      email,
      phone,
      purpose,
      couponCode: isCouponCodeValid ? couponCode : undefined,
      bookingDate: !_.isNull(bookingDate)
        ? getBookingDateString(bookingDate)
        : null,

      selectedSlot: { startTime: startTime.time, endTime: endTime.time },
      numberOfHours,
    };

    if (showBillingDetails) {
      data.website = website;
      data.gst = gst;
      data.companyname = companyname;
      data.address = address;
    }

    console.log(data);
    // return;

    let res = null;
    try {
      res = await axios.post(
        "https://blackelephantdigital.com/api/public/studio.php/order",
        data
      );
      // console.log(res.data);
    } catch (e) {
      console.log("here", e.response);

      if (e.response) {
        if (e.response.data.msg === "Coupon code is already used!") {
          setErrors({ ...errors, coupon: "Coupon code is already used!" });
          return;
        } else if (e.response.data.msg === "Invalid coupon code!") {
          setErrors({ ...errors, coupon: "Invalid coupon code!" });
          return;
        }
        console.log(e.response.data);
        return;
      }

      return;
    }

    const { id, amount, currency } = res.data;

    displayRazorPay(
      id,
      amount,
      currency,
      res.data.name,
      res.data.email,
      res.data.phone,
      res.data.address
    );
  }

  function isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function applyCouponCode() {
    if (_.isEmpty(email)) {
      setErrors({ ...errors, email: "Email Address is required!" });
      return;
    }
    if (!_.isEmpty(couponCode)) {
      try {
        const response = await axios.post(
          "https://blackelephantdigital.com/api/public/studio.php/verifycoupon",
          { coupon: couponCode, email }
        );
        console.log(response);
        setIsCouponCodeValid(true);
        setDiscountAmount(response.data.amount);
        setErrors({ ...errors, coupon: undefined });
      } catch (e) {
        console.log(e.response);

        if (e.response && e.response.status === 400) {
          setErrors({ ...errors, coupon: e.response.data.msg });
        }
      }
    } else {
      setErrors({ ...errors, coupon: "Please enter coupon code!" });
      return;
    }
  }

  function getBookingDateString(date) {
    const d = new Date(date);

    return `${d.getDate() < 10 ? "0" : ""}${d.getDate()}-${
      d.getMonth() < 9 ? "0" : ""
    }${d.getMonth() + 1}-${d.getFullYear()}`;
  }

  function getTodayDate(date) {
    const d = date;
    return `${d.getFullYear()}-${d.getMonth() < 9 ? "0" : ""}${
      d.getMonth() + 1
    }-${d.getDate() < 10 ? "0" : ""}${d.getDate()}`;
  }

  function handleDateChange(e) {
    setBookingDate(e.target.value);
    console.log(e.target.value);
    let date = moment(e.target.value).format("DD-MM-YYYY");
    setBookingDate2(date);
    setStartTime({});
    setEndTime({});
  }

  async function fetchDateBookedSlots() {
    setFetchingBookedSlots(true);
    try {
      const res = await axios.get(
        `https://blackelephantdigital.com/api/public/studio.php/booked-time-slots/${getBookingDateString(
          bookingDate
        )}`
      );

      setFetchingBookedSlots(false);

      let data = res.data;

      const slots = data.map((item) => {
        const time = item.time_slot.split("-");
        const slot = {
          startTime: time[0],
          endTime: time[1],
        };

        return slot;
      });

      console.log(slots);
      setBookedSlots(slots);
    } catch (e) {
      setFetchingBookedSlots(false);

      console.log(e);
    }
  }

  function removeCoupon() {
    setIsCouponCodeValid(false);
    setCouponCode("");
    setDiscountAmount(0);
  }

  const paymentSuccessJSX = (
    <div className="studiobooking__success">
      <h1 className="studiobooking__success__heading">Booking Successful!</h1>
      <div className="studiobooking__success__texttop">
        Thankyou for booking with us! Please note the following details for
        reference.
        <br />
        <div className="studiobooking__success__paymentid">
          <strong>Payment ID: </strong>
          {paymentId}
        </div>
        <div className="studiobooking__success__orderid">
          <strong>Order ID: </strong>
          {orderId}
        </div>
      </div>
      <br />
      <br />

      <Link to="/studio/" className="btn-dark">
        back to studio
      </Link>
    </div>
  );

  const formJSX = (
    <form className="studiobooking__form" onSubmit={onFormSubmit}>
      <div className="studiobooking__grid">
        <div className="studiobooking__grid__left">
          <h1 className="studiobooking__heading">Studio Booking</h1>
          <br />
          <div className="studiobooking__atntxt">
            Studio Services are available for Rs.1500 per hour, plus GST.
            <br />
            <br />
            P.S. : We do special rates for schools and all education-related
            work. Get in touch if you represent a school, university or NGO!
            <br />
            <br />
          </div>
          <label className="studiobooking__form__label">
            General Information
          </label>
          <div className="studiobooking__form__inputgroup row">
            <div className="col col-12 col-md-6">
              <input
                // className="connect__form__input"
                onChange={handleFirstNameChange}
                className="studiobooking__form__input"
                type="text"
                name="firstnane"
                placeholder="First Name"
                value={firstname}
                required
              />
            </div>
            <div className="col col-12 col-md-6">
              <input
                // className="connect__form__input"
                onChange={handleLastNameChange}
                className="studiobooking__form__input"
                type="text"
                name="lastname"
                placeholder="Last Name"
                value={lastname}
                required
              />
            </div>
            {/* <br /> */}
          </div>
          <div className="studiobooking__form__inputgroup row">
            <div className="col col-12 col-md-6">
              <input
                onChange={handleEmailchange}
                className="studiobooking__form__input"
                type="email"
                name="email"
                placeholder="Email Address"
                value={email}
                required
              />

              {!_.isUndefined(errors.email) && (
                <small style={{ color: "red" }}>{errors.email}</small>
              )}
            </div>

            <div className="col col-12 col-md-6">
              <input
                onChange={handlePhoneChange}
                className="studiobooking__form__input"
                type="text"
                name="phone"
                placeholder="Contact Number"
                value={phone}
                required
              />
            </div>
          </div>

          <div className="studiobooking__form__inputgroup">
            <input
              onChange={handlePurposeChange}
              className="studiobooking__form__input"
              type="text"
              name="phone"
              placeholder="Purpose"
              value={purpose}
              required
            />
          </div>

          <br />
          <br />
          {!showBillingDetails ? (
            <button
              className="studiobooking__form__label"
              onClick={() => setShowBillingDetails(true)}
            >
              Add Business Details (Optional) +
            </button>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Billing Details (for business invoices)
                </h2>
                <button
                  style={{ marginTop: 0 }}
                  onClick={() => setShowBillingDetails(false)}
                >
                  <strong>
                    <i class="fas fa-times"></i>
                  </strong>
                </button>
              </div>

              <div className="studiobooking__form__inputgroup">
                <input
                  onChange={handleCompanyNameChange}
                  className="studiobooking__form__input"
                  type="text"
                  name="Website"
                  placeholder="Company Name"
                  value={companyname}
                  required
                />

                <input
                  onChange={handleWebsiteChange}
                  className="studiobooking__form__input"
                  type="text"
                  name="Website"
                  placeholder="Company Website (Optional)"
                  value={website}
                  required
                />
              </div>
              <br />

              <input
                onChange={handleAddressChange}
                className="studiobooking__form__input"
                type="text"
                name="address"
                placeholder="Registered Address"
                value={address}
                required
              />

              <input
                onChange={handleGstChange}
                className="studiobooking__form__input"
                type="text"
                name="Website"
                placeholder="GST"
                value={gst}
                required
              />
              <br />
            </>
          )}
          <br />
          <div className="row">
            <div className="col col-12 col-md-6">
              <label className="studiobooking__form__label">
                Select a booking date
              </label>
              <br />
              <div className="studiobooking__form__inputgroup">
                <input
                  onChange={handleDateChange}
                  className="studiobooking__form__input"
                  type="date"
                  defaultValue={todayDate}
                  min={getTodayDate(new Date())}
                ></input>
              </div>
            </div>
          </div>
          <br />
          <br />
          <label className="studiobooking__form__label">
            Select a time slot
          </label>

          <TimeRangePicker2
            onTimeSelect={onChangeTimePicker}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            loading={fetchingBookedSlots}
            selectedDate={bookingDate}
            bookedSlots={bookedSlots}
          />

          {!_.isUndefined(errors.selectedSlot) && (
            <small style={{ color: "red" }}>{errors.selectedSlot}</small>
          )}
          <div className="studiobooking__text1">
            If your preferred slot is booked, call us and let us know. We will
            try our best to accommodate you.
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="studiobooking__grid__right">
          <h3>
            <span className="studiobooking__yellow">Date:</span>{" "}
            {getBookingDateString(bookingDate)}
          </h3>
          {!_.isEmpty(startTime) && !_.isEmpty(endTime) && (
            <h3>
              <span className="studiobooking__yellow">Selected Slot:</span>{" "}
              {startTime.time} - {endTime.time}
            </h3>
          )}
          {!_.isEmpty(startTime) && !_.isEmpty(endTime) && (
            <h3>
              <span className="studiobooking__yellow">Total Time:</span>{" "}
              {endTime.value - startTime.value} hour
              {endTime.value - startTime.value > 1 ? "s" : ""}
            </h3>
          )}
          {!isCouponCodeValid ? (
            <div className="studiobooking__coupon">
              <input
                onChange={handleCouponCodeChange}
                className="studiobooking__form__input studiobooking__form__input--couponinput"
                type="text"
                placeholder="Coupon Code"
                value={couponCode}
              />

              <button
                className="studiobooking__coupon__btn"
                type="button"
                role="button"
                onClick={applyCouponCode}
              >
                Apply Code
              </button>
              {!_.isUndefined(errors.coupon) && (
                <small style={{ color: "red" }}>{errors.coupon}</small>
              )}
            </div>
          ) : (
            <div className="studiobooking__appliedcoupon">
              <i class="far fa-check-circle"></i> Coupon Code&nbsp;
              <span className="studiobooking__appliedcoupon__coupontext">
                {couponCode}
              </span>
              &nbsp;is applied !
              <button
                title="Remove Coupon"
                className="studiobooking__appliedcoupon__btn"
                onClick={removeCoupon}
                type="button"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          )}
          <br />
          <div></div>

          <div className="studiobooking__checkout">
            <div className="studiobooking__checkout__field">
              <div className="studiobooing__checkout__field__label">
                Booking Amount
              </div>
              <div className="studiobooing__checkout__field__amount">
                ₹{BASE_AMOUNT * numberOfHours}
              </div>
            </div>
            {discountAmount > 0 && (
              <div className="studiobooking__checkout__field">
                <div className="studiobooing__checkout__field__label">
                  Discount
                </div>
                <div className="studiobooing__checkout__field__amount">
                  - &#8377;{discountAmount}
                </div>
              </div>
            )}

            <div className="studiobooking__checkout__field">
              <div className="studiobooing__checkout__field__label">
                GST (18%)
              </div>
              <div className="studiobooing__checkout__field__amount">
                &#8377;
                {(BASE_AMOUNT * numberOfHours - discountAmount) * TAX}
              </div>
            </div>

            <div className="studiobooking__checkout__field studiobooking__checkout__field--total">
              <div className="studiobooing__checkout__field__label">Total</div>
              <div className="studiobooing__checkout__field__amount">
                &#8377;
                {BASE_AMOUNT * numberOfHours -
                  discountAmount +
                  (BASE_AMOUNT * numberOfHours - discountAmount) * TAX}
              </div>
            </div>
          </div>
          <br />
          <button className="btn btn-dark studiobooking__form__submit">
            book now
          </button>
        </div>
      </div>
    </form>
  );

  return (
    <Layout isStudio>
      <div className="studiobooking">
        {!isPaymentSuccessfull ? formJSX : paymentSuccessJSX}
      </div>
    </Layout>
  );
}
