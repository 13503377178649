import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./style.scss";

import spinner from "../../../images/Spinner-1s-200px.gif";

export default function TimeRangePicker2({
  startTime,
  setStartTime = () => {},
  endTime,
  setEndTime = () => {},
  loading = false,
  selectedDate,
  bookedSlots = [],
}) {
  const timeIntervals = [
    { time: "10:30", value: 1 },
    { time: "11:30", value: 2 },
    { time: "12:30", value: 3 },
    { time: "13:30", value: 4 },
    { time: "14:30", value: 5 },
    { time: "15:30", value: 6 },
    { time: "16:30", value: 7 },
    { time: "17:30", value: 8 },
    { time: "18:30", value: 9 },
    { time: "19:30", value: 10 },
  ];

  const timeValueMap = {
    "10:30": 1,
    "11:30": 2,
    "12:30": 3,
    "13:30": 4,
    "14:30": 5,
    "15:30": 6,
    "16:30": 7,
    "17:30": 8,
    "18:30": 9,
    "19:30": 10,
  };

  const [endTimeValues, setEndTimeValues] = useState([]);
  const [startTimeValues, setStartTimeValues] = useState([]);

  useEffect(() => {
    let values = timeIntervals.filter(function (item, i) {
      let flag = true;
      if (i == timeIntervals.length - 1) flag = false;

      const currentTime = new Date();

      const intervalTime = new Date(selectedDate);

      const time = item.time.split(":");
      intervalTime.setHours(time[0]);
      intervalTime.setMinutes(time[1]);

      console.log(intervalTime.getHours() + ":" + intervalTime.getMinutes());

      if (currentTime - intervalTime > 0) {
        console.log(currentTime - intervalTime);
        flag = false;
        console.log("here");
      }

      bookedSlots.forEach((slot) => {
        if (
          timeValueMap[item.time] >= timeValueMap[slot.startTime] &&
          timeValueMap[item.time] < timeValueMap[slot.endTime]
        ) {
          flag = false;
        }
      });

      return flag;
    });

    setStartTimeValues(values);
  }, [JSON.stringify(bookedSlots), selectedDate]);

  useEffect(() => {
    if (!_.isEmpty(startTime)) handleEndTimeValues();
  }, [startTime]);

  function getValueOfEarliestBookedTime(value) {
    let smallestValue = 11;
    bookedSlots.forEach((item) => {
      if (
        timeValueMap[item.startTime] < smallestValue &&
        timeValueMap[item.startTime] > value
      ) {
        smallestValue = timeValueMap[item.startTime];
      }
    });

    return smallestValue;
  }

  function handleEndTimeValues() {
    const smallestBookedValue = getValueOfEarliestBookedTime(startTime.value);
    console.log("smallest Booked Value " + smallestBookedValue);
    const values = timeIntervals.filter(function (item) {
      let flag = true;

      if (item.value <= startTime.value) {
        flag = false;
      } else {
        bookedSlots.forEach((slot) => {
          if (
            smallestBookedValue !== null &&
            item.value > smallestBookedValue
          ) {
            flag = false;
          }
        });
      }

      return flag;
    });

    console.log(values);
    setEndTimeValues(values);
  }

  function handleStartTimeChange(e) {
    setStartTime(JSON.parse(e.target.value));
    setEndTime({});
  }

  function handleEndTimeChange(e) {
    setEndTime(JSON.parse(e.target.value));
  }

  function isBookingClosed() {
    const currentDate = new Date();
    const dateSelected = new Date(selectedDate);
    //   if(currentDate.)

    return (
      _.isEqual(
        currentDate.toLocaleDateString(),
        dateSelected.toLocaleDateString()
      ) && startTimeValues.length === 0
    );
  }

  const startTimeOptions = startTimeValues.map((item, i) => (
    <option
      key={`st-option-${i}`}
      className="trp__select__option"
      value={JSON.stringify(item)}
    >
      {item.time}
    </option>
  ));

  const endTimeOptions = endTimeValues.map((item, i) => (
    <option
      key={`et-option-${i}`}
      className="trp__select__option"
      value={JSON.stringify(item)}
    >
      {item.time}
    </option>
  ));

  return (
    <div className="trp" id="time-slot-picker">
      <select
        className="trp__select"
        value={JSON.stringify(startTime)}
        onChange={handleStartTimeChange}
        disabled={_.isEmpty(startTimeValues) || loading}
      >
        <option style={{ display: "none" }}>---Select Start Time ---</option>
        {startTimeOptions}
      </select>
      &nbsp; - &nbsp;
      <select
        disabled={_.isEmpty(startTime) || _.isEmpty(endTimeValues) || loading}
        className="trp__select"
        onChange={handleEndTimeChange}
        value={JSON.stringify(endTime)}
      >
        <option style={{ display: "none" }}>---Select End Time ---</option>
        {endTimeOptions}
      </select>
      {loading && <img className="trp__loader" src={spinner}></img>}
      {!loading && _.isEmpty(startTimeValues) && !isBookingClosed() && (
        <>
          <br />
          <small style={{ color: "red" }}>
            Sorry! all the slots are booked for this date.
          </small>
        </>
      )}
      {!loading && isBookingClosed() && (
        <>
          <br />
          <small style={{ color: "red" }}>
            Sorry! booking is closed for today.
          </small>
        </>
      )}
    </div>
  );
}
